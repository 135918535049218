'use client';

import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { envConfig } from '@/config/env';
import buildUrl from '@/utils/buildUrl';
import { isExternalLink } from '@/utils/slug/isExternalLink';

export type CustomLinkProps = {
    href?: string | null;
    locale?: string;
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
    Omit<LinkProps, 'href'>;

const Link = forwardRef<HTMLAnchorElement, CustomLinkProps>(
    ({ href, locale, ...props }, ref) => {
        const router = useRouter();
        if (href && isExternalLink(href)) {
            return (
                <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                />
            );
        }

        const url = buildUrl(
            locale,
            href?.split('/').filter(Boolean).join('/'),
        );

        const prefetch = () => router.prefetch(url);

        return (
            <NextLink
                {...(envConfig.NEXT_PUBLIC_DATADOG_ENV === 'prod' && {
                    onMouseEnter: prefetch,
                })}
                prefetch={false}
                ref={ref}
                href={url}
                {...props}
            />
        );
    },
);

export default Link;
